import Vue from 'vue'
import App from './components/App'
import { BootstrapVue } from 'bootstrap-vue'
import router from './router'
import store from './store'
import {$domParents} from '@/components/shared/Helpers'
import slugify from 'slugify'
import sanitizeHtml from 'sanitize-html'
import VeeValidate from 'vee-validate'
import { Validator } from 'vee-validate';
import isValidIBANNumber from './js/ibanFunctions.js'
import VTooltip from 'v-tooltip'
import VueCookie from 'vue-cookie';
import checkout from './checkout'
import eventBus from '@/components/shared/EventBus.js'
import VueYoutube from 'vue-youtube'
import axios from 'axios'
import Toasted from 'vue-toasted';
import VueHead from 'vue-head'
import currencyFilter from './checkout/src/filter/currencyFilter';
import VueSession from 'vue-session';
import Multiselect from 'vue-multiselect';
import VueTheMask from 'vue-the-mask';
import MobileDetect from 'mobile-detect';
import TrackingAPI from '@/api/analytics.js';
import VueTouch from 'vue-touch';
import AsyncComputed from 'vue-async-computed';
import Tabs from 'vue-tabs-component';
import VueMatchHeights from 'vue-match-heights';
import VueMeta from 'vue-meta'
import Simplert from 'vue2-simplert-plugin'
require('vue2-simplert-plugin/dist/vue2-simplert-plugin.css')
import moment from 'moment'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/base.scss'

Vue.use(BootstrapVue)
Vue.use(Simplert)
Vue.use(VueTheMask);
Vue.use(VueSession);

Vue.component('vue-multiselect', Multiselect)

const md = new MobileDetect(window.navigator.userAgent);
Vue.prototype.$isMobile = md.mobile();
Vue.prototype.$isMobileOrTablet = !!(md.mobile() || md.tablet());
Vue.prototype.$analytics = TrackingAPI;
Vue.prototype.$t = function(msg) {
  return msg
};

Vue.filter('currency', currencyFilter);

Vue.use(VueHead);
Vue.use(VueCookie);
Vue.use(VTooltip);
VTooltip.options.defaultTrigger = 'click hover focus'
Vue.use(Tabs);
Vue.use(VueTouch);
Vue.use(VueMatchHeights);
Vue.use(AsyncComputed);
Vue.use(checkout, {
  store,
  mode: 'broker'
});
Vue.use(VueYoutube);

Vue.config.productionTip = false


Vue.use(Toasted)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.$domParents = Vue.prototype.$domParents = $domParents

Vue.slugify = Vue.prototype.$slugify = function(term) {
  return slugify(term, {lower: true, remove: /[$*_+~.()'"!\-:@\d]/g})
}

Vue.use(VeeValidate, {
  classes: true
})

const dictionary = {
  de: {
    messages:{
      min: (fieldLabel, [length]) => {
        return `${fieldLabel} darf minimal ${length} Zeichen lang sein.`
      },
      max: (fieldLabel, [length]) => {
        return `${fieldLabel} darf maximal ${length} Zeichen lang sein.`
      },
      digits: (fieldLabel, [length]) => {
        return `${fieldLabel} muss aus ${length} Zahlen bestehen.`
      },
      required: (fieldLabel) => {
        return `${fieldLabel} ist ein Pflichfeld.`
      },
      email: () => {
        return 'Geben Sie eine gültige E-Mail-Adresse an.'
      },
      phoneNumber: () => {
        return 'Geben Sie eine gültige Telefonnummer an.'
      },
      regex: (fieldLabel) => {
        return `${fieldLabel} enthält ungültige Zeichen.`
      }
    }
  }
};

// Override and merge the dictionaries
Validator.localize(dictionary);

const validator = new Validator({ first_name: 'alpha' });

validator.localize('de');

validator.extend('iban', {
  getMessage: field => 'IBAN ist nicht korrekt',
  validate: (value, countryCode) => {
    return isValidIBANNumber(value, countryCode)
  }
})

validator.extend('1st-char-not-0', {
  getMessage: field => 'Das erste Zeichen kann nicht 0 sein',
  validate(value) {
    return value.charAt(0) !== '0';
  },
});

Vue.sanitizeHtml = Vue.prototype.$sanitizeHtml = function(dirty) {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'span'
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src']
    }
  })
}

Vue.openPDF = Vue.prototype.$openPDF = function(url) {
  eventBus.$emit('previewPDF', url)
}

var isFirstLoad = true
router.beforeEach(function(to, from, next) {
  eventBus.$emit('openNav', false)

  if (!to.query.pdf) {
    eventBus.$emit('previewPDF', '')
    window.scrollTo(0, 0)
  }

  if ((to.query.pdf && to.query.pdf.indexOf('http') === -1) && isFirstLoad) {
    setTimeout(() => {
      eventBus.$emit('previewPDF', to.query.pdf)
    })
  }
  isFirstLoad = false
  next()
})

Vue.filter('formatDate', function (date) {
  return moment(date).format('DD.MM.YYYY')
})

new Vue({
  render: h => h(App),
  router,
  store,
  template: '<App/>',
  components: {App},
  mounted() {
    window.addEventListener('keyup', function(event) {
      eventBus.$emit('keyUp', event.keyCode)
    });
  },
  beforeDestroy() {
    eventBus.$off('keyUp')
  }
}).$mount('#app')
